import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { Match } from "../../interfaces/Match";

const HalfTimeRow = ({match}: {match: Match}) => {
    console.log(match);
    const under = match.bets.find(bet => /Menos de/.test(bet.name));

    return (
        <>
            <TableRow key={match.matchID}>
                <TableCell>{match.matchID}</TableCell>
                <TableCell>{match.league}</TableCell>
                <TableCell>{match.competitors.join(' vs ')}</TableCell>
                <TableCell>{match.htTimer}</TableCell>
                <TableCell>{match.sHTimer}</TableCell>
                <TableCell>{match.totGoals}</TableCell>
                <TableCell>{match.difGoals}</TableCell>
                <TableCell>{under ? `${under.name}: ${under.rate}` : 'N/A'}</TableCell>
                <TableCell>
                    <a href={match.link} target="_blank" rel="noopener noreferrer">
                        {match.link}
                    </a>
                </TableCell>
            </TableRow>        
        </>
    )
}

export { HalfTimeRow }