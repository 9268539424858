import React, { useState, useEffect } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { makeRequest } from '../../api';
import { Options } from "../../interfaces/Options";
import { Match } from "../../interfaces/Match";
import { HalfTimeRow } from "./HalfTimeRow";

const HalfTimeTable = () => {
    const [data, setData] = useState<Match[]>([]);
    const [sortColumn, setSortColumn] = useState<string>('sHTimer'); // Default sorting column
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc'); // Default sort order

    const fetchData = async () => {
        const options: Options = {
            method: 'get',
        };
        const response: any = await makeRequest('api/get_sh_potential_matches', options);
        if (response.status === 200) {
            // Sort the response data directly
            const sortedData = [...response.data].sort((a, b) => {
                const timeToSeconds = (time: string) => {
                    const [minutes, seconds] = time.split(":").map(Number);
                    return minutes * 60 + seconds;
                };
                const aSeconds = timeToSeconds(a.sHTimer);
                const bSeconds = timeToSeconds(b.sHTimer);
                return bSeconds - aSeconds; // for descending order
            });
            setData(sortedData);
        }
    };

    const sortData = (column: string) => {
        if (!Object.prototype.hasOwnProperty.call(data[0], column)) {
            console.error(`Invalid column: ${column}`);
            return;
        }
    
        const order = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(order);
        setSortColumn(column);
    
        const sortedData = [...data].sort((a, b) => {
            let aValue = a[column as keyof Match];
            let bValue = b[column as keyof Match];
    
            if (column === 'sHTimer') {
                const timeToSeconds = (time: string) => {
                    const [minutes, seconds] = time.split(":").map(Number);
                    return minutes * 60 + seconds;
                };
                aValue = timeToSeconds(a.sHTimer);
                bValue = timeToSeconds(b.sHTimer);
            }
    
            if (order === 'asc') {
                return aValue > bValue ? 1 : -1;
            } else {
                return aValue < bValue ? 1 : -1;
            }
        });
    
        setData(sortedData);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <h1>Partidos Terminados 45:00-47:00</h1>
            <Button 
                variant="contained" 
                color="primary"
                onClick={fetchData}
            >
            Refresh data
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => sortData('matchID')}>ID</TableCell>
                            <TableCell onClick={() => sortData('league')}>Liga</TableCell>
                            <TableCell>Partido</TableCell>
                            <TableCell onClick={() => sortData('htTimer')}>Fin PT</TableCell>
                            <TableCell onClick={() => sortData('sHTimer')}>Timer</TableCell>
                            <TableCell onClick={() => sortData('totGoals')}>Tot Goles</TableCell>
                            <TableCell onClick={() => sortData('difGoals')}>Diff Goles</TableCell>
                            <TableCell>Under</TableCell>
                            <TableCell>Link</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((match: Match) => (
                            <HalfTimeRow key={match.matchID} match={match} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export { HalfTimeTable };
